import React, { useState } from 'react';
import myResume from './assets/JakeLee_resume.pdf'
import logo from './images/favicon_white.png'
import './boilerplate.css';
import './App.css';

function App() {
  const [fadeOutOne, setFadeOutOne] = useState(false)
  const [fadeOutTwo, setFadeOutTwo] = useState(false)
  const [fadeOutThree, setFadeOutThree] = useState(false)
  const [fadeOutFour, setFadeOutFour] = useState(false)
  const [fadeOutFive, setFadeOutFive] = useState(false)

  const [isDisabledOne, setIsDisabledOne] = useState(false)
  const [isDisabledTwo, setIsDisabledTwo] = useState(false)
  const [isDisabledThree, setIsDisabledThree] = useState(false)
  const [isDisabledFour, setIsDisabledFour] = useState(false)
  const [isDisabledFive, setIsDisabledFive] = useState(false)

  const [segNumber, setSegNumber] = useState(0)

  const [url, setUrl] = useState('')

  const [allFadedOut, setAllFadedOut] = useState(false)

  // const [buttonFadeOut, setButtonFadeout] = useState(false)

  const urlArray = ['https://www.linkedin.com/in/jkl798/', 'https://github.com/jkjale', 'a', 'https://leetcode.com/u/jkjale789/', myResume]

  


  const handleFadeOut = (e) => {
    let segNum = parseInt(e.target.id[e.target.id.length-1])
    switch(segNum) {
      case 1:
        setFadeOutOne(true)
        setIsDisabledTwo(true)
        setIsDisabledThree(true)
        setIsDisabledFour(true)
        setIsDisabledFive(true)
        setIsDisabledOne(true)
        setTimeout(() => {
          setFadeOutTwo(true)
          setFadeOutThree(true)
          setFadeOutFour(true)
          setFadeOutFive(true)
        }, 1500)
        setTimeout(() => {
          setAllFadedOut(true)
        }, 3000)
        break
      case 2:
        setFadeOutTwo(true)
        setIsDisabledOne(true)
        setIsDisabledThree(true)
        setIsDisabledFour(true)
        setIsDisabledFive(true)
        setIsDisabledTwo(true)
        setTimeout(() => {
          setFadeOutOne(true)
          setFadeOutThree(true)
          setFadeOutFour(true)
          setFadeOutFive(true)
        }, 1500)
        setTimeout(() => {
          setAllFadedOut(true)
        }, 3000)
        break
      case 3:
        setFadeOutThree(true)
        setIsDisabledTwo(true)
        setIsDisabledOne(true)
        setIsDisabledFour(true)
        setIsDisabledFive(true)
        setIsDisabledThree(true)
        setTimeout(() => {
          setFadeOutTwo(true)
          setFadeOutOne(true)
          setFadeOutFour(true)
          setFadeOutFive(true)
        }, 1500)
        setTimeout(() => {
          setAllFadedOut(true)
        }, 3000)
        break
      case 4:
        setFadeOutFour(true)
        setIsDisabledTwo(true)
        setIsDisabledThree(true)
        setIsDisabledOne(true)
        setIsDisabledFive(true)
        setIsDisabledFour(true)
        setTimeout(() => {
          setFadeOutTwo(true)
          setFadeOutThree(true)
          setFadeOutOne(true)
          setFadeOutFive(true)
        }, 1500)
        setTimeout(() => {
          setAllFadedOut(true)
        }, 3000)
        break
      case 5:
        setFadeOutFive(true)
        setIsDisabledTwo(true)
        setIsDisabledThree(true)
        setIsDisabledFour(true)
        setIsDisabledOne(true)
        setIsDisabledFive(true)
        setTimeout(() => {
          setFadeOutTwo(true)
          setFadeOutThree(true)
          setFadeOutFour(true)
          setFadeOutOne(true)
        }, 1500)
        setTimeout(() => {
          setAllFadedOut(true)
        }, 3000)
        break
      default:
        console.log('some error happened')
    }
    setSegNumber(segNum)
  }


  const handleAllFadeIn = () => {
    // setButtonFadeout(false)
    setAllFadedOut(false)
    setFadeOutOne(false)
    setFadeOutTwo(false)
    setFadeOutThree(false)
    setFadeOutFour(false)
    setFadeOutFive(false)
    setIsDisabledOne(false)
    setIsDisabledTwo(false)
    setIsDisabledThree(false)
    setIsDisabledFour(false)
    setTimeout(() => {
      setIsDisabledFive(false)
    }, 100)
  }

  // const handleButtonFadeOut = () => {
  //   handleAllFadeIn()
  //   setButtonFadeout(true)
  // }

  const handleClickGetHref = (e) => {
    let segNum = parseInt(e.target.id[e.target.id.length-1])
    setUrl(urlArray[segNum-1])
  }



  return (
    <>
      <header><img id='logo' src={logo} alt='websitelogo'/></header>
      {!allFadedOut ?
        (<div id='segments-wrapper'>
          <div 
            className={`segment ${fadeOutOne && 'fadeout-background'} ${isDisabledOne && 'disableClick'}`}  
            id='segment-1'
            onClick={handleFadeOut}
          ></div>
          <div 
            className={`segment ${fadeOutTwo&& 'fadeout-background'} ${isDisabledTwo && 'disableClick'}`} 
            id='segment-2'
            onClick={handleFadeOut}
          ></div>
          <div 
            className={`segment ${fadeOutThree && 'fadeout-background'} ${isDisabledThree && 'disableClick'}`} 
            id='segment-3'
            onClick={handleFadeOut}
          ></div>
          <div 
            className={`segment ${fadeOutFour && 'fadeout-background'} ${isDisabledFour && 'disableClick'}`} 
            id='segment-4'
            onClick={handleFadeOut}
          ></div>
          <div 
            className={`segment ${fadeOutFive && 'fadeout-background'} ${isDisabledFive && 'disableClick'}`} 
            id='segment-5'
            onClick={handleFadeOut}
          ></div>
        </div>) :
        (<div className='fadein-background'>

          <div className="close" onClick={handleAllFadeIn}></div>
          
          <div id='buttonPage' className={!allFadedOut && 'fadeout-background'}>
            <a href={url} target="_blank" rel="noopener noreferrer" className={(segNumber === 3 || segNumber === 5) && 'disableClick'}>
              <div className='iconButton' id={`iconButton-${segNumber}`} onClick={handleClickGetHref}></div>
            </a>
            
          </div>
        </div>)
      }
    </>
  );
}

// {segNumber === 3 && <div id='aboutMe'>Hi, my name is Jake and I am a Frontend Developer. I am passionate about building beautiful products using the most cutting edge technologies, and I enjoy fast paced environments that provide resources to enhance my professional skills.</div>}

export default App;
